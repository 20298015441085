.cont-reviwes {
    width: 50%;
}
.grw-review-inner {
    margin: 10px;
    padding: 15px;
    position: relative;
}
.wp-google-left {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 20px;
    text-overflow: ellipsis!important;
    max-width: 90%;
    overflow-x: hidden;
}
.wp-google-left .cont-title-google-wp {
    display: flex;
    flex-direction: column;
    text-overflow: ellipsis!important;
}
.wp-google-left .cont-title-google-wp .wp-google-name {
    color: #154fc1;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 16px;
}
.wp-google-left .cont-title-google-wp .wp-google-time {
    color: #555;
    font-size: 13px;
}
.grw-review .wp-google-feedback {
    height: 100px!important;
    overflow-y: auto!important;
    padding-right: 5px;
}
.wp-google-text {
    color: #222!important;
    font-size: 15px!important;
    line-height: 24px!important;
    max-width: 100%!important;
    overflow: hidden!important;
    white-space: pre-wrap!important;
    word-break: break-word!important;
}
.grw-review-inner>svg {
    position: absolute!important;
    top: 10px!important;
    right: 10px!important;
    width: 18px!important;
    height: 18px!important;
}
.grw-review {
    display: flex;
    position: relative!important;
    justify-content: center!important;
    align-items: center!important;
    flex-shrink: 0!important;
    width: 100%!important
}
.grw-review .wp-google-feedback::-webkit-scrollbar {
    width: 4px!important
}
.grw-review .wp-google-feedback::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)!important;
    -webkit-border-radius: 4px!important;
    border-radius: 4px!important
}

.grw-review .wp-google-feedback::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px!important;
    border-radius: 10px!important;
    background: #ccc!important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5)!important
}

.grw-review .wp-google-feedback::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc!important
}

.grw-header-inner {
    width: 25%;
}
.wp-google-place {
    display: flex;
    gap: 15px;
}
.wp-google-left-h img {
    border-radius: 50px;
}
.wp-google-name a {
    text-decoration: none;
    color: var(--negro);
    font-weight: 600;
}
.wp-google-rating {
    font-weight: 600;
    font-size: 18px;
    color: #fb8e28;
}
.wp-google-powered img {
    margin-top: 10px;
}
.wp-google-wr {
    display: flex;
    justify-content: center;
}
.wp-google-wr a {
    color: #fff!important;
    padding: 6px 15px 10px!important;
    outline: 0!important;
    border-radius: 27px!important;
    background: #0a6cff!important;
    white-space: nowrap!important;
    vertical-align: middle!important;
    text-shadow: 1px 1px 0 #888!important;
    box-shadow: 0 0 2px rgba(0,0,0,.12),0 2px 4px rgba(0,0,0,.24)!important;
    user-select: none!important;
    transition: all .2s ease-in-out!important;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
}
.wp-google-wr a svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    padding: 1px;
    background: #fff;
    border-radius: 50%;
}
.serv-info-mob {
    display: none;
}
.mobile-only {
    display: none;
}
.desktop-only {
    display: block;
}