@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

:root {
  --rosado: #E51F5F;
  --rosado-claro: #FFA1BD;
  --rosado-transparente: #cd265281;
  --negro-transaprente: #00000083;
  --negro: #000000;
  --blanco: #ffffff;
  --gris-claro: #dbdbdb;
}

* {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  width: 100%;
}

.columna-completa {
  width: calc(100vw * 0.7);
  margin: 0 auto;
}
.cont-seccion-formulario {
  position: relative;
}
.form-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
}
.form-background .rosado {
  background-color: var(--rosado);
  height: 65%;
  width: 100%;
}
.form-background .blanco {
  background-color: var(--blanco);
  height: 35%;
  width: 100%;
}
.cont-formulario-s {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 30px;
}
.section-form-iz {
  position: relative;
  width: 50%;
}
.section-form-dr {
  width: 50%;
}
.cont-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: -moz-fit-content;
  height: fit-content;
  width: 90%;
  background-color: var(--blanco);
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 20px;
  position: relative;
  z-index: 3;
  box-shadow: 1px 7px 8px rgba(0, 0, 0, 0.349);
}
.cont-text-header {
 padding: 20px 0;
}
.cont-text-header img {
  width: 250px;
}
.cont-form-top-der {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: fit-content;
}
.cont-input-item {
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-bottom: 15px;
}
.cont-input-item input {
  padding: 10px;
  border-radius: 10px;
  border: none;
  width: calc(100% - 20px);
  background-color: var(--gris-claro);
}
.cont-input-item select, .drop-down {
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: var(--gris-claro);
  /*width: calc(100% - 20px);*/
}
.Dropdown-control {
  padding: 10px !important;
  border-radius: 10px !important;
  border: none !important;
  background-color: var(--gris-claro) !important;
  font-size: 14px;
}
.btn-send {
  padding: 10px 30px;
  background-color: var(--rosado);
  border-radius: 50px;
  border: var(--blanco) solid 2px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  color: var(--blanco);
  cursor: pointer;
  width: fit-content;
}
.cont-hiper-text {
  text-align: center;
}
.cont-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cont-hiper-text .text-about-content {
  padding: 10px;
  font-weight: 500;
  width: 90%;
  background-color: var(--blanco);
  margin: 20px auto;
  box-shadow: 1px 7px 8px rgba(0, 0, 0, 0.349);
  border-radius: 20px;
}
.cont-hiper-text .read-more {
  font-size: 14px;
  margin-top: -10px;
}
.cont-hiper-text img {
  width: 35%;
  margin: 0 auto;
  cursor: pointer;
  animation: moveSticker 1s infinite alternate, rotateSticker 1s infinite linear;
  filter: drop-shadow(1px 7px 8px rgba(0, 0, 0, 0.349));
}
.text-our-experienced {
  font-family: "Fredoka";
  font-size: 18px;
  margin-top: 20px;
}
@keyframes moveSticker {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(20px);
  }
}

@keyframes rotateSticker {
  0% {
      transform: rotate(0deg);
  }
  50% {
      transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.cont-seccion-resenas {
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: start;
  margin: 30px 0;
}
.resenas-izquierda {
  width: 40%;
}
.resenas-izquierda img {
  width: 100%;
}
.resenas-derecha {
  width: 60%;
}
.grw-header {
  display: flex;
}
.cont-carusel-resenas {
  width: 70%;
  margin: 0 auto;
}
.text-about-us {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  color: var(--rosado);
  font-size: 25px;
  cursor: pointer;
  margin-right: 80px;
}
.text-about-us * {
  cursor: pointer;
}
.container-faqs {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}
.faq {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  width: calc(100vw / 5);
  background-color: var(--rosado);
  padding: 60px 25px 25px 25px;
  border-radius: 50px;
}
.title-faqs {
  font-size: 45px;
  font-weight: 700;
  color: var(--rosado);
  text-align: center;
  z-index: 1;
}
.title-faq {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  z-index: 1;
}
.title-faq span {
  font-size: 20px;
  font-weight: 800;
  text-align: center;
}
.cont-svg-chek {
  width: 35px;
  height: 35px;
}
.sub-title {
  font-weight: 700;
  text-align: center;
  z-index: 1;
}
.faq-text-info {
  font-weight: 400;
  text-align: center;
  z-index: 1;
}
.back-faq {
  background-color: #FFE8EF;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 20px;
  left: 0;
  z-index: 0;
  border-radius: 50px;
}
.seccion-carusel-pets {
  margin-top: 40px;
  padding-bottom: 50px;
  position: relative;
}
.seccion-carusel-pets h2 {
  position: relative;
  font-size: 45px;
  font-weight: 700;
  color: var(--rosado);
  text-align: center;
  margin-top: 60px;
  margin-bottom: 20px;
  z-index: 1;
}
.carusel-pets {
  height: 400px;
  background-position: center;
  background-size: cover;
  border-radius: 25px;
  margin: 0 5px;
  z-index: 1;
}
.backgound-sliders {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.backgound-sliders .bl {
  background-color: var(--blanco);
  height: 50%;
}
.backgound-sliders .rs {
  background-color: var(--rosado);
  height: 50%;
}
.react-multi-carousel-dot button {
  border: none !important;
}
.react-multi-carousel-dot--active button {
  background: #0741ff !important;
}
.cont-btn-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 20px 0;
}
.cont-btn-loading p {
  color: var(--rosado);
  font-weight: 600;
}
.seccion-formulario2 {
  display: flex;
  padding-top: 40px;
  background-color: var(--rosado);
}
.seccion-formulario2 .iz {
  width: 60%;
  position: relative;
  padding: 20px 60px 0 0;
}
.seccion-formulario2 .dr {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form2 {
  box-shadow: none;
  margin: 0;
  border-radius: 0 180px 0 0;
  padding: 60px 40px 0 0;
  width: 100%;
}
.form2 .cont-input-item {
  width: 30%;
}
.fondo-form2 {
  background-color: #FFA1BD;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 200px 0 0;
}
.form2-patitas {
  height: 120px;
  width: 100%;
  padding-left: 40px;
}
.form2-patitas svg {
  height: 100%;
}
.contact-number .icon {
  height: 40px;
  width: 40px;
}
.cont-contact-number {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 60px;
}
.contact-number {
  display: flex;
  gap: 10px;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: flex-end;
}
.contact-number p {
  font-size: 40px;
  font-weight: 600;
  color: var(--blanco);
}
.cont-patas-contact {
  height: 70px;
  width: 70px;
  position: absolute;
  top: 0;
  right: -80px;
}
.cont-city-name {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  position: absolute;
  bottom: -35px;
  right: 0;
}
.cont-city-name .icon {
  height: 20px;
  width: 20px;
}
.cont-city-name p {
  font-size: 25px;
  font-weight: 600;
}
.patas2 {
  position: absolute;
  top: -140px;
  right: -90px;
  width: 180px;
}
.cont-video-tlc {
  width: 80%;
  border: var(--blanco) solid 2px;
  border-radius: 25px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
}
.cont-btn-book {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 30px 0;
  gap: 15px;
}
.cont-btn-book p {
  font-size: 13px;
  font-family: 'Fredoka';
  letter-spacing: -0.02em;
  font-weight: 400;
}

.cont-btn-book p strong {
  font-size: 14px;
  font-family: 'Fredoka';
  letter-spacing: -0.02em;
  font-weight: 700;
}
.form2 .cont-btn-book {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0;
  gap: 15px;
  width: 30%;
}
.contact-number p a {
  font-size: 40px;
  font-weight: 600;
  color: var(--blanco);
  text-decoration: none;
}









/***?????*/
.cont-input-item span {
  font-size: 13px;
  color: var(--rosado);
  font-weight: 500;
}

.label-form {
  display: flex;
  gap: 10px;
  align-items: center;
}

.label-form p {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.label-form .cont-icon {
  width: 10px;
}

.vanilla-calendar {
  width: 400px !important;
}

.success-text {
  max-width: 300px;
  font-size: 30px;
  font-weight: 600;
  color: var(--rosado);
  text-align: center;
}

.text-header-p {
  font-size: 30px;
  font-weight: 500;
  border-bottom: var(--rosado) solid 2px;
  text-align: center;
  margin: 10px auto;
}

.text-header-p b {
  color: var(--rosado);
  font-size: 40px;
}

.text-header-p2 {
  font-size: 20px;
  text-align: center;
  margin: 10px auto 30px auto;
}
.cont-types {
  margin-bottom: 20px;
}
.cont-sizes {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.cont-selector-size {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: #b9b9b9 solid 1px;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}
.select-size {
  border: var(--rosado) solid 2px;
}
.cont-selector-size img {
  height: 70px;
}
.cont-selector-size label {
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}
.cont-selector-size input {
  cursor: pointer;
}
.cont-type-service {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.btn-service {
  padding: 10px 15px;
  background-color: var(--gris-claro);
  border-radius: 10px;
  cursor: pointer;
}
.select {
  background-color: var(--rosado);
  color: var(--blanco);
}
.btn-service label {
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}
.btn-service input {
  cursor: pointer;
}
.span-error {
  font-size: 13px;
  color: var(--rosado);
  font-weight: 500;
  text-align: center;
  width: 100%;
  display: block;
}
.img-ban {
  position: absolute;
  right: 0;
  top: 400px;
  height: 300px;
}
.img-ban-movile {
  display: none;
}

@media (max-width: 600px) {
  .img-ban-movile {
    display: block;
    width: 100%;
    margin-top: 40px;
  }
  .img-ban {
    display: none;
  }
  .columna-completa {
    width: 90%;
    margin: 0 auto;
  }
  .cont-formulario-s {
    flex-direction: column;
  }
  .section-form-iz {
    width: 100%;
  }
  .section-form-dr {
    width: 100%;
  }
  .grw-header {
    flex-direction: column;
  }
  .grw-header-inner {
    width: 100% !important;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .cont-carusel-resenas {
    width: 90% !important;
  }
  .cont-seccion-resenas {
    height: fit-content;
    flex-direction: column;
    gap: 40px;
    margin-top: -20px;
  }
  .resenas-izquierda {
    width: 100%;
  }
  .resenas-derecha {
    width: 100%;
  }
  .text-about-us {
    margin: 20px auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .title-faqs {
    font-size: 35px;
    text-align: center;
  }
  .container-faqs {
    flex-wrap: wrap;
    gap: 45px;
  }
  .faq {
    width: calc(100vw / 1.2);
  }
  .seccion-carusel-pets h2 {
    font-size: 35px;
  }
  .seccion-formulario2 {
    flex-direction: column-reverse;
    overflow-x: hidden;
  }
  .seccion-formulario2 .iz {
    width: 100%;
    padding: 10px 0 0 0;
  }
  .form2 {
    margin: 0;
    border-radius: 40px 40px 0 0;
    padding: 20px 0 0 0;
  }
  .fondo-form2 {
    background-color: #FFA1BD;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 40px 40px 0 0;
  }
  .form2 .cont-input-item {
    width: 49%;
  }
  .form2 .cont-btn-book {
    margin-top: 20px;
    width: 100%;
  }
  .form2-patitas {
    height: 120px;
    width: 100%;
    padding-left: 0;
    display: flex;
    justify-content: center;
  }
  .seccion-formulario2 .dr {
    width: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .contact-number p {
    font-size: 30px;
  }
  .contact-number .icon {
    height: 30px;
    width: 30px;
  }
  .cont-city-name {
    bottom: -25px;
  }
  .cont-city-name p {
    font-size: 18px;
  }
  .cont-city-name .icon {
    height: 16px;
    width: 16px;
  }
  .cont-video-tlc {
    width: 90%;
  }
}